<template>
  <div>
    <portal to="header-right">
      <div class="revive-logo">
        <img src="@/assets/images/revive-logo.png" alt="logo" />
      </div>
    </portal>
    <h3 class="color-primary mb-4">
      Follow the steps to send your {{ repairer }} product to Revive for a full assessment
    </h3>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
<!-- back button fix-->
        <button
          v-for="(step, index) in steps"
          :key="index"
          :class="getLinkClass(step)"
          :disabled="isDisabled(step)"
          @click="navigate(step)"
        >
          <span class="step-number">{{ step.step }}</span>

          <span class="d-none d-md-inline">{{ ' ' }}{{ step.label }}</span>
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <router-view />
    </div>
  </div>
</template>

<script>
import { pages } from '@/shared';

export default {
  name: 'Revive',
  props: {
    device: {
      default: null,
    },
  },
  data() {
    return {
      steps: [],
      selectedDevice: null,
      isLastStep: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    pages() {
      return pages;
    },
    repairer() {

      if(this.$route.fullPath.search('/revive-dyson') >= 0) {
        return 'Dyson'
      }
      return 'Apple'
    }
  },
  methods: {
    getLinkClass(link) {
      const active = this.$router.currentRoute.name === link.name;
      const classes = ['btn', 'btn-link', 'nav-item', 'nav-link'];
      if (active) {
        classes.push('active');
      } else if (!link.visited) {
        classes.push('disabled');
      }
      return classes.join(' ');
    },
    navigate(link) {
      if (this.$router.currentRoute.name === link.name) return;
      const route = this.selectedDevice
        ? link.path.replace(':device', this.selectedDevice)
        : link.path;
      this.$router.push(route);
    },
    isDisabled(link) {
      return !link.visited || (link.name !== 'send' && this.$router.currentRoute.name === 'send') || (link.name !== 'send-dyson' && this.$router.currentRoute.name === 'send-dyson');
    },
    init() {
      if(this.$route.fullPath === "/revive-dyson/select-device" || this.$route.fullPath.split('/').slice(-3)[0] === "revive-dyson") {
        this.steps = [
          {
            ...pages['revive-dyson'].children.selectDevice,
            visited: true,
          },
          {
            ...pages['revive-dyson'].children.packageOption,
            visited: false,
          },
          {
            ...pages['revive-dyson'].children.packageInstructions,
            visited: false,
          },
          {
            ...pages['revive-dyson'].children.sendDevice,
            visited: false,
          },
        ];
      } else {
        this.steps = [
          {
            ...pages['revive-apple'].children.selectDevice,
            visited: true,
          },
          {
            ...pages['revive-apple'].children.removeDevice,
            visited: false,
          },
          {
            ...pages['revive-apple'].children.packageDevice,
            visited: false,
          },
          {
            ...pages['revive-apple'].children.sendDevice,
            visited: false,
          },
        ];
      }

      const currentStep = this.steps.find((step) => step.name === this.$router.currentRoute.name);
      this.steps.forEach((step, index) => {
        if (step.step <= currentStep.step) {
          this.steps[index].visited = true;
        }
      });
      if (currentStep.step === 3 && ["barrel-vacuums", "upright-vacuums", "air-purifiers"].includes(this.selectedDevice) ) {
        console.log('here')
        this.steps[1].visited = false;
      }
    },
  },
  watch: {
    device: {
      handler(value) {
        if (value) {
          this.selectedDevice = value;
        }
      },
      immediate: true,
    },
    $route() {
      this.init();

    },
  },
};
</script>

<style scoped lang="scss">
.revive-logo {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 2px solid #b4252a;

  img {
    width: 100%;
    max-width: 150px;
  }
}

#nav-tab {
  .nav-item {
    color: #723f98;
    border: 1px solid #723f98;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    margin-right: 5px;
    width: 175px;
    text-align: left;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:last-child {
      margin-right: 0;
    }

    .step-number {
      display: inline-block;
      height: 25px;
      width: 25px;
      font-weight: bold;
      color: #fd7e14;
      border: 1px solid #723f98;
      border-radius: 100%;
      text-align: center;
      background-color: #ffffff;
    }

    &.active {
      background-color: #723f98;
      color: #ffffff;
    }

    @media (max-width: 991.98px) {
      width: 160px;
      padding: 10px;
    }

    @media (max-width: 767.98px) {
      width: auto;
    }
  }
}

.tab-content {
  padding: 15px;
  border: 1px solid #723f98;
  border-radius: 0 6px 6px 6px;
}
</style>
